import { BasicButton } from '@/components/buttons/BasicButton';
import Modal from '@/components/modal/Modal';
import { DropdownGeneric, TextInput } from '@/shared/components/fields';
import { DropdownItem } from '@/shared/components/items/DropdownItem';
import { ITable } from '@/types/tables';
import { faFilePen, faFloppyDisk, faTrashAlt, faWavePulse } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

interface IModalWorkspaceConfig {
	open: boolean;
	close(): void;
	table: ITable;
	onSave(table: ITable): void;
	onDelete(uid: string): void;
}

const BASE_SORT: ITable['defaultsort'] = {
	dataindex: '',
	datatype: '',
	order: 'desc',
};

const STATES = [
	{
		key: 'published',
		title: 'Active',
		icon: faWavePulse,
		color: '#00BF8A',
	},
	{
		key: 'drafted',
		title: 'In draft',
		icon: faFilePen,
		color: '#999999',
	},
	{
		key: 'archived',
		title: 'Completed',
		icon: faFloppyDisk,
		color: '#E89255',
	},
];

export const ModalWorkspaceConfig = ({ open, close, table, onSave, onDelete }: IModalWorkspaceConfig) => {
	const [_table, setTable] = useState<ITable>(table);
	const [error, setError] = useState<string>('');
	const selectedColumn = _table.columns.find((column) => column.dataindex === _table.defaultsort?.dataindex);
	const selectedStatus = STATES.find((s) => s.key === _table?.filters?.taskstatus);

	useEffect(() => {
		setTable(table);
	}, [table]);

	const handleSubmit = () => {
		if (!table.name) {
			setError('Name is required');
			return;
		}
		onSave(_table);
	};

	const toggleOrder = () => {
		setTable((prev) => ({ ...prev, defaultsort: { ...prev.defaultsort, order: prev.defaultsort?.order === 'asc' ? 'desc' : 'asc' } }));
	};

	const resetSort = () => {
		setTable((prev) => ({ ...prev, defaultsort: BASE_SORT }));
	};

	const resetStatus = () => {
		setTable((prev) => ({ ...prev, taskstatus: '' }));
	};

	const handleClose = () => {
		setTable(table);
		close();
	};

	return (
		<Modal open={open}>
			<div className="w-64 space-y-4">
				<div className="flex justify-between">
					<TextInput
						id="name"
						label="Table name"
						placeholder="Enter table name"
						className="rounded-none px-0"
						value={_table.name}
						onChange={(e) => setTable((prev) => ({ ...prev, name: e.target.value }))}
					/>
					<FontAwesomeIcon className="cursor-pointer text-red-400" icon={faTrashAlt} onClick={() => onDelete(table.uid)} />
				</div>
				<div>
					<div className="mb-2 flex justify-between">
						<p className="text-xs font-medium text-gray-500">Default sort</p>
						{selectedColumn && (
							<button className="text-xs text-red-400" onClick={resetSort}>
								Reset
							</button>
						)}
					</div>
					<div className="flex gap-2">
						<DropdownGeneric
							collection={_table.columns}
							renderItem={({ item }) => (
								<DropdownItem
									title={item.title}
									onClick={() =>
										setTable((prev) => ({
											...prev,
											defaultsort: { ...prev.defaultsort, dataindex: item.dataindex, datatype: item.datatype },
										}))
									}
								/>
							)}
							button={
								<BasicButton as="div" className="w-full">
									{selectedColumn ? selectedColumn.title : 'Select column'}
								</BasicButton>
							}
						/>
						<BasicButton onClick={toggleOrder}>{_table.defaultsort?.order === 'asc' ? 'Asc' : 'Desc'}</BasicButton>
					</div>
				</div>
				{_table.type === 'tasks' && (
					<div>
						<div className="mb-2 flex justify-between">
							<p className="text-xs font-medium text-gray-500">Task status</p>
							{selectedStatus && (
								<button className="text-xs text-red-400" onClick={resetStatus}>
									Reset
								</button>
							)}
						</div>
						<DropdownGeneric
							collection={STATES}
							renderItem={({ item: { color, ...rest } }) => (
								<DropdownItem
									style={{ color }}
									{...rest}
									selected
									onClick={() => setTable((prev) => ({ ...prev, filters: { ...prev.filters, taskstatus: rest.key } }))}
								/>
							)}
							button={
								<BasicButton as="div" className="w-full">
									{selectedStatus ? selectedStatus.title : 'Select status'}
								</BasicButton>
							}
						/>
					</div>
				)}
				{error && <p className="text-xs text-red-400">{error}</p>}
				<div className="flex gap-2">
					<BasicButton className="w-full" type="button" onClick={handleClose}>
						Close
					</BasicButton>
					<BasicButton onClick={handleSubmit} variant="primary" className="w-full">
						Save
					</BasicButton>
				</div>
			</div>
		</Modal>
	);
};
