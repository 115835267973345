import { IExtendedContractTemplate } from '@/features/contract_templates/types';
import { getContractTemplate } from '@/features/contract_templates/utils/api';
import { getContractsByTemplate } from '@/features/contracts/utils/api';
import { IOption } from '@/features/datasources/types';
import { IField } from '@/features/fields/types';
import { TableCustom } from '@/features/tables/TableCustom';
import { updateTable } from '@/features/tables/utils/api';
import { getTaskTemplate } from '@/features/templates/api';
import { IExtendedTemplate } from '@/features/templates/types';
import { runInRestrictedEnvironment } from '@/shared/utils/executor';
import { IColumn, ITable } from '@/types/tables';
import { fetcher } from '@/utils/api';
import { getTasksByTemplate } from '@/utils/api/tasks';
import { compareStrings, parseName } from '@/utils/helpers';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqBy } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { ModalWorkspaceConfig } from './ModalWorkspaceConfig';

interface ITableWorkspace {
	table: ITable;
	onDelete(uid: string): void;
}

const tasksFields: IField[] = [
	{
		label: 'Responsibles',
		key: 'responsibles',
		type: 'responsibles',
		required: false,
	},
	{
		label: 'Talents',
		key: 'task_talents',
		type: 'task_talents',
		required: false,
	},
	{
		label: 'Customer',
		key: '_customer',
		type: 'customer',
		required: false,
	},
];

const contractFields: IField[] = [
	{
		label: 'Owners',
		key: 'parties',
		type: 'owners',
		required: false,
	},
	{
		label: 'Suppliers',
		key: 'parties',
		type: 'contract_suppliers',
		required: false,
	},
	{
		label: 'Talents',
		key: 'parties',
		type: 'talents',
		required: false,
	},
	{
		label: 'Viewers',
		key: 'parties',
		type: 'viewers',
		required: false,
	},
];

const SORTABLE_COLUMNS = ['snippet'];
const FILTERABLE_COLUMNS = ['responsibles', 'task_talents', 'text', 'select', 'multiselect'];

export const TableWorkspace = (props: ITableWorkspace) => {
	const [table, setTable] = useState<ITable>(props.table);
	const [template, setTemplate] = useState<IExtendedTemplate | IExtendedContractTemplate>();
	const [dataSource, setDataSource] = useState<any[]>([]);
	const [options, setOptions] = useState<Record<string, IOption[]>>({});
	const [loading, setLoading] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const router = useRouter();

	const extraFields = table.type === 'tasks' ? tasksFields : contractFields;
	const allFields = [
		...(template?.fields ?? []),
		{
			key: 'created',
			type: 'date',
			label: 'Created at',
		},
		...extraFields,
	];

	let filteredData = dataSource;
	if (table.filters?.['taskstatus']) {
		filteredData = dataSource.filter((d) => table.filters['taskstatus'].includes(d._state));
	}
	const allResponsibles = filteredData
		.filter((d) => d?.responsibles)
		.map((d) => d.responsibles ?? [])
		.flat();
	const allTalents = filteredData
		.filter((d) => d?.task_talents)
		.map((d) => d.task_talents ?? [])
		.flat();
	const uniqueResponsibles = uniqBy(allResponsibles, (r) => r?.uid);
	const uniqueTalents = uniqBy(allTalents, (r) => r?.uid);
	const selectColumns = useMemo(() => table.columns.filter((c) => c.datatype === 'select' || c.datatype === 'multiselect'), [table.columns]);

	useEffect(() => {
		fetchOptions();
	}, [selectColumns]);

	useEffect(() => {
		if (table?.template) {
			fetchExtendedTemplate();
			fetchDataSource();
		}
	}, [table.template]);

	useEffect(() => {
		setTable(table);
	}, [props.table]);

	const fetchDataSource = async () => {
		setLoading(true);
		let dataSource = [];
		switch (table.type) {
			case 'tasks': {
				dataSource = await getTasksByTemplate(table.template);
				break;
			}
			case 'contracts': {
				dataSource = await getContractsByTemplate(table.template);
			}
		}
		setDataSource(dataSource);
		setLoading(false);
	};

	const fetchExtendedTemplate = async () => {
		let template: IExtendedTemplate | IExtendedContractTemplate;
		switch (table.type) {
			case 'tasks': {
				template = await getTaskTemplate(table.template);
				break;
			}
			case 'contracts': {
				template = await getContractTemplate(table.template);
			}
		}
		setTemplate(template);
	};

	const fetchOptions = async () => {
		const options = {};
		await Promise.all(
			selectColumns.map(async (column) => {
				const fieldOptions = await fetcher(`/datasources/options?field=${column.field}`);
				options[column.dataindex] = fieldOptions;
			})
		);
		setOptions(options);
	};

	const navigateToTask = (uid: string) => {
		router.push(`/projects/${uid}`);
	};

	const onUpdateTable = async (table: ITable) => {
		const { name, defaultsort, filters } = table;
		setIsEditing(false);
		setTable((prev) => ({ ...prev, name, defaultsort, filters }));
		await updateTable(table.uid, { name, defaultsort, filters });
	};

	const sortColumn = (column: IColumn) => (a, b) => {
		const { datatype, snippet } = column;
		switch (datatype) {
			case 'snippet': {
				const valA = runInRestrictedEnvironment(snippet, { row: a });
				const valB = runInRestrictedEnvironment(snippet, { row: b });
				if (!isNaN(valA?.value) && !isNaN(valB?.value)) {
					return valA.value - valB.value;
				}
				return compareStrings(String(valA?.value), String(valB?.value));
			}
			default: {
				return 0;
			}
		}
	};

	const getColumnFilter = (column: IColumn) => {
		const { datatype } = column;
		switch (datatype) {
			case 'responsibles': {
				return uniqueResponsibles.map((r) => ({ text: parseName(r), value: r.uid }));
			}
			case 'task_talents': {
				return uniqueTalents.map((r) => ({ text: parseName(r), value: r.uid }));
			}
			case 'text': {
				const allValues = dataSource.map((d) => ({ text: d[column.dataindex], value: d[column.dataindex] }));
				return uniqBy(allValues, (v) => v.value);
			}
			case 'select':
			case 'multiselect': {
				return options?.[column.dataindex]?.map((o) => ({ text: o.text, value: o.uid }));
			}
			default: {
				return [];
			}
		}
	};

	const filterColumn = (column: IColumn) => (value: any, record: any) => {
		const { datatype } = column;
		switch (datatype) {
			case 'responsibles': {
				return record.responsibles?.some((r) => r.uid === value);
			}
			case 'task_talents': {
				return record.task_talents?.some((r) => r.uid === value);
			}
			case 'text': {
				return record[column.dataindex] === value;
			}
			case 'select': {
				return record[column.dataindex] === value;
			}
			case 'multiselect': {
				return record[column.dataindex]?.some((uid) => uid === value);
			}
			default: {
				return true;
			}
		}
	};

	return (
		<div>
			<ModalWorkspaceConfig open={isEditing} close={() => setIsEditing(false)} table={table} onSave={onUpdateTable} onDelete={props.onDelete} />
			<div className="mb-2 flex items-center gap-2">
				<h1 className="text-2xl">{table?.name}</h1>
				<FontAwesomeIcon className="cursor-pointer" onClick={() => setIsEditing(true)} icon={faCog} />
			</div>
			<TableCustom
				table={table}
				data={filteredData}
				fields={allFields}
				loading={loading}
				filter={{
					getFilterItems: getColumnFilter,
					onFilter: filterColumn,
					filterableColumns: FILTERABLE_COLUMNS,
				}}
				sorting={{
					onSort: sortColumn,
					sortableColumns: SORTABLE_COLUMNS,
				}}
				onRowClick={(record) => navigateToTask(record.uid)}
			/>
		</div>
	);
};
