import { chatAtom } from '@/atoms/chat';
import { IMessage } from '@/features/chat/types';
import { DropdownGeneric } from '@/shared/components/fields';
import { DropdownItem } from '@/shared/components/items/DropdownItem';
import { ImageWithFallback, ORGANISATION_AVATERS } from '@/utils/ImageWithFallback';
import { getUnreadMessages, markMessageAsRead } from '@/utils/api/rooms';
import { parseName } from '@/utils/helpers';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

export default function Inbox({ employee }) {
	const [chatState, setChatState] = useRecoilState(chatAtom);
	const { unreadMessages } = chatState;
	const router = useRouter();

	useEffect(() => {
		fetchUnreadMessages();
	}, [employee]);

	const fetchUnreadMessages = async () => {
		const messages = await getUnreadMessages(employee);
		setChatState((prev) => ({ ...prev, unreadMessages: messages, employee }));
	};

	const markAsRead = async (msg: string, room: string) => {
		const unread = unreadMessages.filter((m) => m.uid !== msg);
		setChatState((prev) => ({ ...prev, unreadMessages: unread }));
		await markMessageAsRead(msg, room);
	};

	const columns: ColumnsType<IMessage> = [
		{
			key: 'room',
			dataIndex: 'room',
			title: 'Room',
			render: (room) => <p>{room.name ? room.name : 'No name'}</p>,
		},
		{
			key: 'sender',
			dataIndex: 'sender',
			title: 'From',
			render: (sender) => <p>{parseName(sender)}</p>,
		},
		{
			key: 'sender',
			dataIndex: 'sender',
			title: 'Company',
			render: (sender) => (
				<ImageWithFallback
					src={ORGANISATION_AVATERS(sender?.space?.uid)}
					className="h-auto w-auto max-w-[80px]"
					width="80"
					height="80"
					alt=""
				/>
			),
		},
		{
			key: 'timestamp',
			dataIndex: 'timestamp',
			title: 'Date',
			render: (date) => <p>{dayjs(date).format('DD MMM YYYY HH:mm')}</p>,
		},
		{
			key: 'options',
			title: 'Options',
			render: (_, record) => {
				const options = [
					{
						title: 'Go to chat',
						onClick: () =>
							router.push({
								pathname: `/projects/${record.task}`,
								query: { tab: 'chat', room: record.room.uid },
							}),
					},
					{
						title: 'Mark as read',
						onClick: () => markAsRead(record.uid, record.room.uid),
					},
				];
				return (
					<DropdownGeneric
						collection={options}
						renderItem={({ item }) => <DropdownItem className="m-1" {...item} />}
						button={<FontAwesomeIcon className="cursor-pointer px-2 py-1" icon={faEllipsisV} size="lg" />}
						placement="left"
					/>
				);
			},
		},
	];

	return (
		<div className="w-full">
			<h2 className="mb-2 text-xl">Unread messages</h2>
			<Table columns={columns} dataSource={unreadMessages} rowKey={'uid'} />
		</div>
	);
}
