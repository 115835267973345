import { ImageWithFallback } from '@/utils/ImageWithFallback';
import { faArrowsRepeat } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';

const fakeData = [
	{
		company: 'Eghead',
		companyLogo: 'https://assets-global.website-files.com/651d373a603cbf3022cba538/6537c90a99d667b609caedaa_eghed_logo.png',
		title: 'Mobile App for Policy Management',
		customer: 'Gjensidige Forsikring ASA',
		period: '31/01/24 - 01/01/25',
		daysleft: <p className="font-bold text-red-500">1 day</p>,
		action: (
			<p className="cursor-pointer font-bold hover:text-green-500">
				<FontAwesomeIcon icon={faArrowsRepeat} size="lg" className="mr-2" /> Extend
			</p>
		),
	},
	{
		company: 'Holly',
		companyLogo: 'https://assets-global.website-files.com/651d373a603cbf3022cba538/6537c8a2cdb184d2b3df1ab5_holly-logo.png',
		title: 'Legal for Policy Management',
		customer: 'Norsk Hydro ASA',
		period: '31/01/24 - 01/01/25',
		daysleft: <p className="font-bold text-red-500">1 day</p>,
		action: (
			<p className="cursor-pointer font-bold hover:text-green-500">
				<FontAwesomeIcon icon={faArrowsRepeat} size="lg" className="mr-2" /> Extend
			</p>
		),
	},
	{
		company: 'Mum',
		companyLogo: 'https://assets-global.website-files.com/651d373a603cbf3022cba538/6537c40d57ac41a723121baf_mum-logo.webp',
		title: 'Scrum Master for Digitalization',
		customer: 'Storebrand ASA',
		period: '01/01/24 - 01/01/25',
		daysleft: <p className="font-bold text-red-500">1 day</p>,
		action: (
			<p className="cursor-pointer font-bold hover:text-green-500">
				<FontAwesomeIcon icon={faArrowsRepeat} size="lg" className="mr-2" /> Extend
			</p>
		),
	},
	{
		company: 'Queenslab',
		companyLogo: 'https://assets-global.website-files.com/651d373a603cbf3022cba538/6537c3b42c052e71756be67d_queenslab-logo.svg',
		title: 'Expert Developer for Migration project',
		customer: 'Kongsberg Gruppen ASA',
		period: '01/01/24 - 01/01/25',
		daysleft: <p className="font-bold text-red-500">1 day</p>,
		action: (
			<p className="cursor-pointer font-bold hover:text-green-500">
				<FontAwesomeIcon icon={faArrowsRepeat} size="lg" className="mr-2" /> Extend
			</p>
		),
	},
	{
		company: 'Regentor',
		companyLogo: 'https://assets-global.website-files.com/651d373a603cbf3022cba538/6537c3396cb11eb52abdd72e_regentor-logo.png',
		title: 'Test and QA for new product',
		customer: 'Atea ASA',
		period: '01/01/24 - 01/01/25',
		daysleft: <p className="font-bold text-red-500">1 day</p>,
		action: (
			<p className="cursor-pointer font-bold hover:text-green-500">
				<FontAwesomeIcon icon={faArrowsRepeat} size="lg" className="mr-2" /> Extend
			</p>
		),
	},
	{
		company: 'Zebra Consulting',
		companyLogo: 'https://assets-global.website-files.com/651d373a603cbf3022cba538/6537c2bf37beda4b1a82339c_zebra-consulting-logo.svg',
		title: 'Project Lead for upcoming Mobile app',
		customer: 'Tomra Systems ASA',
		period: '01/01/24 - 01/01/25',
		daysleft: <p className="font-bold text-red-500">1 day</p>,
		action: (
			<p className="cursor-pointer font-bold hover:text-green-500">
				<FontAwesomeIcon icon={faArrowsRepeat} size="lg" className="mr-2" /> Extend
			</p>
		),
	},
	{
		company: 'Aliby',
		companyLogo: 'https://assets-global.website-files.com/651d373a603cbf3022cba538/6537caab310958edb4152b48_aliby-logo.png',
		title: 'AI-Powered Chatbot Implementation for Customer Support',
		customer: 'Telenor Group',
		period: '01/02/24 - 31/10/24',
		daysleft: <p className="font-bold text-red-500">2 days</p>,
		action: (
			<p className="cursor-pointer font-bold hover:text-green-500">
				<FontAwesomeIcon icon={faArrowsRepeat} size="lg" className="mr-2" /> Extend
			</p>
		),
	},
	{
		company: 'Crepido',
		companyLogo: 'https://assets-global.website-files.com/651d373a603cbf3022cba538/6537ca35c2f3d1e973f84f92_crepido-logo.png',
		title: 'Online Banking App User Experience Redesign',
		customer: 'Skandiabanken ASA',
		period: '01/02/24 - 31/01/25',
		daysleft: <p className="font-bold text-red-500">2 days</p>,
		action: (
			<p className="cursor-pointer font-bold hover:text-green-500">
				<FontAwesomeIcon icon={faArrowsRepeat} size="lg" className="mr-2" /> Extend
			</p>
		),
	},
	{
		company: 'Blead',
		source: 'https://images.crunchbase.com/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/nelpe9lvsxxkxfzpiy8p',
		title: 'Tax Data Analytics for Fraud Detection',
		customer: 'Skatteetaten',
		period: '02/10/24 - 31/01/25',
		daysleft: <p className="font-bold text-yellow-500">12 days</p>,
		action: (
			<p className="cursor-pointer font-bold hover:text-green-500">
				<FontAwesomeIcon icon={faArrowsRepeat} size="lg" className="mr-2" /> Extend
			</p>
		),
	},
	{
		company: 'Cure',
		companyLogo: 'https://assets-global.website-files.com/651d373a603cbf3022cba538/65200082aec4d7216172da5f_cure_logo.svg',
		title: 'IoT Solutions Architecture and Deployment',
		customer: 'Itera ASA',
		period: '15/02/24 - 31/01/25',
		daysleft: <p className="font-bold text-yellow-500">17 days</p>,
		action: (
			<p className="cursor-pointer font-bold hover:text-green-500">
				<FontAwesomeIcon icon={faArrowsRepeat} size="lg" className="mr-2" /> Extend
			</p>
		),
	},
];

const columns = [
	{
		key: 'companyLogo',
		title: 'Company',
		dataIndex: 'companyLogo',
		render: (companyLogo, record) => (
			<>
				{companyLogo ? (
					<ImageWithFallback
						src={companyLogo}
						alt="Company Logo"
						width={80}
						height={0}
						style={{ filter: 'invert(100%)' }}
						className="h-12 w-12"
					/>
				) : (
					record.company
				)}
			</>
		),
	},
	{
		key: 'customer',
		title: 'Customer',
		dataIndex: 'customer',
	},
	{
		key: 'title',
		title: 'Title',
		dataIndex: 'title',
	},
	{
		key: 'period',
		title: 'Period',
		dataIndex: 'period',
	},
	{
		key: 'daysleft',
		title: 'Days left',
		dataIndex: 'daysleft',
	},
	{
		key: 'action',
		title: 'Action',
		dataIndex: 'action',
	},
];

export const PotentialExtensionsBornDigital = () => {
	return (
		<div>
			<div className="mb-2 flex items-center">
				<FontAwesomeIcon icon={faArrowsRepeat} size="xl" className="mr-2" />
				<h1 className="text-2xl">Potential extensions</h1>
			</div>
			<Table dataSource={fakeData} columns={columns} pagination={false} />
		</div>
	);
};
