import { fetcher } from '@/utils/api';
import { IContractTableConfigBody, IContractTemplateBody } from '../types';

export const getContractTemplates = async () => {
	return await fetcher('/contract_templates');
};

export const getContractTemplate = async (uid: string) => {
	return await fetcher(`/contract_templates/${uid}`);
};

export const getContractTemplateFields = async () => {
	return await fetcher(`/contract_templates/fields`);
};

export const createContractTemplate = async (template: IContractTemplateBody) => {
	return await fetcher(`/contract_templates`, {
		method: 'POST',
		body: JSON.stringify({ template }),
	});
};

export const updateContractTemplate = async (uid: string, template: IContractTemplateBody) => {
	return await fetcher(`/contract_templates/${uid}`, {
		method: 'PUT',
		body: JSON.stringify({ template }),
	});
};

export const deleteContractTemplate = async (uid: string) => {
	return await fetcher(`/contract_templates/${uid}`, {
		method: 'DELETE',
	});
};

export const getContractTableConfig = async () => {
	return await fetcher('/contract_templates/table_config');
};

export const createContractTableConfig = async (config: IContractTableConfigBody) => {
	return await fetcher(`/contract_templates/table_config`, {
		method: 'POST',
		body: JSON.stringify(config),
	});
};

export const updateContractTableConfig = async (uid: string, config: IContractTableConfigBody) => {
	return await fetcher(`/contract_templates/table_config/${uid}`, {
		method: 'PUT',
		body: JSON.stringify(config),
	});
};
