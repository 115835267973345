import { fetcher } from '../index';

export const getTasksByTemplate = async (template: string) => {
	return await fetcher(`/tasks/templates/${template}`, {
		method: 'GET',
	});
};

export const getRandomTask = async (template: string) => {
	return await fetcher(`/tasks/random?template=${template}`);
};

export const addAssignee = async ({ task, user, roles }: { task: string; user: string; roles: string[] }) => {
	return await fetcher(`/tasks/${task}/assignee`, {
		method: 'POST',
		body: JSON.stringify({ user, roles }),
	});
};

export const removeAssignee = async ({ task, user }) => {
	return await fetcher(`/tasks/${task}/assignee`, {
		method: 'DELETE',
		body: JSON.stringify({ user }),
	});
};

export const toggleTaskPin = async (task) => {
	return await fetcher(`/tasks/${task}/pin`, {
		method: 'POST',
	});
};

export const addTemplateToTask = async (task, template) => {
	return await fetcher(`/tasks/${task}/template`, {
		method: 'POST',
		body: JSON.stringify({ template }),
	});
};

export const updateTask = async (task: string, payload: Record<string, any>) => {
	return await fetcher(`/tasks/${task}`, {
		method: 'PUT',
		body: JSON.stringify(payload),
	});
};
export const deleteTask = async (uid: string) => {
	return await fetcher(`/tasks/${uid}`, {
		method: 'DELETE',
	});
};
