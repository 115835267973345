import { atom } from 'recoil';

interface IChatAtom {
	unreadMessages: any[];
	ref: string;
	room: string;
	employee: string;
	open: boolean;
}

export const chatAtom = atom<IChatAtom>({
	key: 'chatAtom',
	default: {
		unreadMessages: [],
		ref: '',
		room: '',
		employee: '',
		open: false,
	},
});
